export const queryFragmentBiennialEntryThumbnail = {
  biennialEntry: {
    fields: [
      'title',
      'slug',

      'dateStart',
      'dateEnd',
      'hideDates',

      'editionIndex',
      'country',
      'city',
      'titleAnnotation',
      'colors',

      'editionIndex'
    ],
    populate: {
      colors: true
    }
  }
}

export const queryFragmentBiennialEntriesThumbnail = {
  biennialEntries: {
    fields: [
      'title',
      'slug',

      'dateStart',
      'dateEnd',
      'hideDates',

      'editionIndex',
      'country',
      'city',
      'titleAnnotation',
      'colors',

      'editionIndex'
    ],
    populate: {
      colors: true
    }
  }
}
